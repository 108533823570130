import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyRihannaTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="Rihanna"
        description="Fan initiative"
        image={image}
      />
      <CaseStudyDescriptionSection
        title={
          <h3>
            Rihanna & her team reached out to Brendan after seeing the success
            of the Taylor Swift fan initiative with the task of building a viral
            and engaging fan community
          </h3>
        }
        list={
          <ul>
            <li>
              Facebook application that automatically merged a fan’s name and
              photos within Rihanna’s album artwork in order to create a custom
              Facebook Cover
            </li>
            <li>1,000,000+ fans participated in the application</li>
            <li>
              These technology Hook Points were <span>also leveraged</span> by
              VH1, CMT and Comedy Central
            </li>
            <li>100,000,000+ people saw the Rihanna Branded Facebook Pages</li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-nordic-naturals"}
        text="Explore our work with Rihanna further. Apply to work with us"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyRihannaTemplate
